
import Vue from "vue";

import { authMapActions } from "@/store/modules/auth";
import { logoPath } from "@/helpers/generalHelpers";

export default Vue.extend({
  name: "confirmAccount",
  created() {
    this.validateConfirmAccount();
  },
  data() {
    return {
      error: "",
      message: "",
      loading: false,
      confirmRequestSent: false,
      credentials: {
        email: ""
      },
      resetToken: false,
      resetError: ""
    };
  },
  methods: {
    ...authMapActions(["confirmAccount", "requestConfirmAccount"]),
    getLogoPath() {
      return logoPath();
    },
    async validateConfirmAccount(): Promise<void> {
      this.loading = true;
      localStorage.removeItem("access");
      localStorage.removeItem("account_confirmation");
      if (this.hasConfirmAccountToken()) {
        const token = this.$route.query["token"] as string;
        localStorage.setItem("account_confirmation", token);
        this.confirmAccount()
          .then(() => {
            localStorage.removeItem("account_confirmation");
            this.$router.replace({ query: {} }).catch(() => {});
          })
          .catch(e => {
            this.$router.replace({ query: {} }).catch(() => {});
            this.error = "Error Confirming Account";
            this.$bugSnagClient.notify(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$router.replace("/login").catch(() => {});
      }
    },
    hasConfirmAccountToken() {
      return this.$route.query["token"] && this.$route.query["token"] !== "";
    },
    async initRequestConfirmAccount(): Promise<void> {
      this.loading = true;
      this.resetError = "";
      try {
        const { email } = this.credentials;
        const { message } = await this.requestConfirmAccount({ email });
        this.message = message;
        this.confirmRequestSent = true;
      } catch (e) {
        this.resetError = e.message;
      } finally {
        this.loading = false;
      }
    },
    confirmAccountResetToken() {
      this.resetToken = true;
    }
  }
});
